export var getPersistBetweenPages = function (apiSettings, logicDialogParams) {
    if (typeof apiSettings === 'boolean') {
        return apiSettings;
    }
    if (typeof logicDialogParams === 'boolean') {
        return logicDialogParams;
    }
    return true;
};
export var getScreenSettingsParams = function (apiSettings, logicDialogParams) {
    var _a, _b;
    var enableSpeechToText = getSettingsValueWithDefault(false, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.enableSpeechToText, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.enableSpeechToText);
    var enableWhisperSTT = getSettingsValueWithDefault(false, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.enableWhisperSTT, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.enableWhisperSTT);
    var enableEmojis = getSettingsValueWithDefault(true, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.enableEmojis, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.enableEmojis);
    var withHeader = getSettingsValueWithDefault(true, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.withHeader, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.withHeader);
    var hideInput = getSettingsValueWithDefault(false, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.allTextInput, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.hideInput); // Do not ask me why in wbb api it disable input is "allTextInput"
    var enableFullScreen = getSettingsValueWithDefault(true, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.enableFullScreen, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.enableFullScreen);
    var kioskMode = getSettingsValueWithDefault(false, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.kioskMode, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.kioskMode);
    var enableUploadFile = getSettingsValueWithDefault(true, apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.enableUploadFile, logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.enableUploadFile);
    var fullScreenSettings = getSettingsValueWithDefault(false, (apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.fullScreen) || (logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.fullScreen));
    var showLeft = getSettingsValueWithDefault(false, (apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.positionLeft) || ((_a = logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.style) === null || _a === void 0 ? void 0 : _a.showLeft));
    var enableDownloadChat = getSettingsValueWithDefault(false, (apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.enableDownloadChat) || (logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.enableDownloadChat));
    var allowFeedback = apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.allowFeedback;
    var popupSize = apiSettings === null || apiSettings === void 0 ? void 0 : apiSettings.popupSize;
    return {
        withInput: !hideInput,
        withHeader: withHeader,
        enableFullScreen: enableFullScreen,
        kioskMode: kioskMode,
        fullScreenSettings: fullScreenSettings,
        enableSpeechToText: enableSpeechToText,
        enableWhisperSTT: enableWhisperSTT,
        enableEmojis: enableEmojis,
        enableUploadFile: enableUploadFile,
        enableDownloadChat: enableDownloadChat,
        showLeft: showLeft,
        allowFeedback: allowFeedback,
        popupSize: popupSize,
        minPopupWidth: (_b = logicDialogParams === null || logicDialogParams === void 0 ? void 0 : logicDialogParams.screen) === null || _b === void 0 ? void 0 : _b.minPopupWidth
    };
};
export var queryVarAsBoolean = function (key, defaultValue) {
    var urlParams = new URLSearchParams(window.location.search);
    var result = urlParams.get(key);
    if (result === null)
        return defaultValue;
    return result === 'true';
};
var getSettingsValueWithDefault = function (defaultValue, apiSettings, logicDialogParams) {
    if (typeof apiSettings === 'boolean') {
        return apiSettings;
    }
    else if (typeof logicDialogParams === 'boolean') {
        return logicDialogParams;
    }
    return defaultValue;
};
